import { useCallback } from 'react';

export const useErrorHandler = () => {
  // FIXME: TO BE IMPLEMENT
  const throwError = useCallback((error: any, message: string) => {
    console.warn(error, message);
  }, []);

  return {
    throwError,
  };
};

import EventEmitter from 'eventemitter3';
import { getByShortcut } from '@/infra/items/ItemRepository';
import Archive from '@/domain/archives/index';
import type IEventEmitter from 'eventemitter3';

interface Props {
  type: 'launch' | 'archive';
  shortcutId: number;
}
export function itemsGetByShortcuts(
  { getByShortcut },
  props: Props
): IEventEmitter {
  const ee = new EventEmitter();
  getByShortcut(props)
    .then((res) => {
      const items = res.items.map((item) => new Archive(item));

      ee.emit('SUCCESS', {
        ...res,
        items,
      });
    })
    .catch((error) => {
      if (error.message === 'ServerError') {
        ee.emit('SERVER_ERROR', error);
        return;
      }
      ee.emit('ERROR', error);
    });
  return ee;
}

export default itemsGetByShortcuts.bind(itemsGetByShortcuts, {
  getByShortcut,
});
export type ResolvedData = {
  items: Archive[];
  totalCount: number;
  eventIds: number[];
};

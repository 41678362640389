import createConfigs from '@/config/index';
import fetch from '@/infra/fetch/index';

interface base {
  url: string;
  fetch: typeof fetch;
}

const paths = {
  getEvents: 'v1/events/',
  getArchivedEvents: 'v1/events/archive',
  getEvent: 'v1/events/',
};

export function getEventCtx({ url, fetch }: base) {
  return fetch(`${url}`)
    .then(async (v) => {
      const res = await v.json();
      return res;
    })
    .catch((err) => {
      throw err;
    });
}

export function getArchivedEventCtx({ url, fetch }: base) {
  return fetch(`${url}`)
    .then(async (v) => {
      const res = await v.json();
      return res;
    })
    .catch((err) => {
      throw err;
    });
}

export function getEventDetailCtx({ url, fetch }: base, { eventId }) {
  return fetch(`${url}${eventId}`)
    .then(async (v) => {
      const res = await v.json();
      return res;
    })
    .catch((err) => {
      throw err;
    });
}

export const getEvents = getEventCtx.bind(getEventCtx, {
  fetch,
  url: new URL(paths.getEvents, createConfigs().apiServer.origin).href,
});

export const getArchivedEvents = getArchivedEventCtx.bind(getArchivedEventCtx, {
  fetch,
  url: new URL(paths.getArchivedEvents, createConfigs().apiServer.origin).href,
});

export const getEventDetail = getEventDetailCtx.bind(getEventDetailCtx, {
  fetch,
  url: new URL(paths.getEvent, createConfigs().apiServer.origin).href,
});

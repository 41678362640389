import Shortcut from '@/domain/shortcuts';
import {
  getForLaunch,
  getForArchive,
} from '@/infra/shortcuts/shortcutsRepository';
import EventEmitter from 'eventemitter3';

export function shortcutsGetAll({ getAll }) {
  const ee = new EventEmitter();
  getAll()
    .then((res) => {
      const data: Shortcut[] = res.shortcuts.map(
        (chunk) => new Shortcut(chunk)
      );
      ee.emit('SUCCESS', { shortcuts: data });
    })
    .catch((error) => {
      if (error.message === 'ServerError') {
        ee.emit('SERVER_ERROR', error);
        return;
      }
      ee.emit('ERROR', error);
    });
  return ee;
}

export const shorcutsForLaunchGetAll = shortcutsGetAll.bind(shortcutsGetAll, {
  getAll: getForLaunch,
});

export const shorcutsForArchiveGetAll = shortcutsGetAll.bind(shortcutsGetAll, {
  getAll: getForArchive,
});

import { Router, useRouter } from 'next/router';
import { useEffect } from 'react';

const cachedScrollPositions: Array<any> = [];

export const useCachedScrollPosition = () => {
  const router = useRouter();

  useEffect(() => {
    if ('scrollRestoration' in window?.history) {
      window.history.scrollRestoration = 'manual';
      let shouldScrollRestore;

      Router.events.on('routeChangeStart', () => {
        cachedScrollPositions.push([window.scrollX, window.scrollY]);
      });

      Router.events.on('routeChangeComplete', () => {
        if (shouldScrollRestore) {
          const { x, y } = shouldScrollRestore;
          window.scrollTo(x, y);
          shouldScrollRestore = false;
        }
      });

      router.beforePopState(() => {
        const [x, y] = cachedScrollPositions.pop();
        shouldScrollRestore = { x, y };

        return true;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {};
};

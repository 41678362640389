import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { LinkWithi18n } from '@/infra/link/withi18n';
import { routes } from '@/config/routes';
import styles from '@/interface/ui/_shared/items/menu.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Search from '@/interface/ui/_shared/footer/icons/search';

type Props = {
  rowNumber: number;
  setRowNumber: (value: number | ((prevVar: number) => number)) => void;
  props: {
    country: string;
    language: string;
    mode?: 'default' | 'pink';
  };
};

const Menu: React.FC<Props> = ({ rowNumber, setRowNumber, props }) => {
  const router = useRouter();
  const isLaunch = /launch/.exec(router.pathname) != null;
  const { country, language } = useSelector((state: RootState) => state.app);

  return (
    <div className={styles.menu}>
      <ul className={styles.ul}>
        <li className={styles.launch} key="launch">
          <LinkWithi18n
            to={routes.launch}
            country={props.country}
            language={props.language}
            mode={props.mode}
          >
            <a className={isLaunch ? styles.activeLink : styles.inActiveLink}>
              Launch
            </a>
          </LinkWithi18n>
          {isLaunch && <div className={styles.underline}></div>}
        </li>
        <li className={styles.li} key="archives">
          <LinkWithi18n
            to={routes.archives}
            country={props.country}
            language={props.language}
            mode={props.mode}
          >
            <a className={!isLaunch ? styles.activeLink : styles.inActiveLink}>
              Archive
            </a>
          </LinkWithi18n>
          {!isLaunch && <div className={styles.underline}></div>}
        </li>
      </ul>
      <div
        className={styles.changeRowNumberButton}
        onClick={() => {
          if (rowNumber === 1) setRowNumber(2);
          else setRowNumber(1);
        }}
      >
        {rowNumber === 1 ? (
          <FontAwesomeIcon
            icon="th-large"
            color={colors.gray_1}
            size="lg"
            className={styles.multipleIcon}
          />
        ) : (
          <FontAwesomeIcon
            icon="square"
            color={colors.gray_1}
            size="lg"
            className={styles.singleIcon}
          />
        )}

        <LinkWithi18n
          to={routes.search}
          country={country}
          language={language}
          mode={props.mode}
        >
          <a className={styles.anchor}>
            <Search />
          </a>
        </LinkWithi18n>
      </div>
    </div>
  );
};

import type { RootState } from '@/store/index';
import colors from '@/constants/colors';
export default Menu;

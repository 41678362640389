import createConfigs from '@/config/index';
import fetch from '@/infra/fetch/index';

interface base {
  url: string;
  fetch: typeof fetch;
}

const paths = {
  getForLaunch: 'v1/shortcuts/launch',
  getForArchive: 'v1/shortcuts/archive',
};

function getAllCtx({ url, fetch }: base) {
  return fetch(url)
    .then(async (v) => {
      const res = await v.json();
      return { shortcuts: res.shortcuts };
    })
    .catch((err) => {
      throw err;
    });
}

export const getForLaunch = getAllCtx.bind(getAllCtx, {
  fetch,
  url: new URL(paths.getForLaunch, createConfigs().apiServer.origin).href,
});

export const getForArchive = getAllCtx.bind(getAllCtx, {
  fetch,
  url: new URL(paths.getForArchive, createConfigs().apiServer.origin).href,
});

import React from 'react';
import styles from '@/interface/ui/_shared/common/ShortcutsResultTitle.module.scss';

interface Props {
  count: number | null;
}

export const ShortcutsResultTitle = ({ count }: Props) => {
  return (
    <div className={styles.contaier}>
      <div className={styles.title}>
        {`${count || 0}`}
        <p className={styles.unit}>{`件`}</p>
      </div>
    </div>
  );
};

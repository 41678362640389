import React from 'react';
import styles from '@/interface/ui/_shared/common/ShortcutTip.module.scss';

interface Props {
  name: string;
  onPress?: () => void;
  active?: boolean;
}

export const ShortcutTip = ({ name, active, onPress }: Props) => {
  return (
    <li
      className={
        active ? styles.tipContainerActive : styles.tipContainerInactive
      }
      key={`${name}`}
      onClick={onPress}
    >
      <a className={active ? styles.activeLink : styles.inActiveLink}>
        {`${name}`}
      </a>
    </li>
  );
};

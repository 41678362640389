import { getByShortcut } from '@/infra/pink/items/ItemRepository';
import Archive from '@/domain/archives/index';
import { itemsGetByShortcuts } from '@/app/items/itemsGetByShortcuts';

export default itemsGetByShortcuts.bind(itemsGetByShortcuts, {
  getByShortcut,
});

export type ResolvedData = {
  items: Archive[];
  totalCount: number;
  eventIds: number[];
};

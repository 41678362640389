import { LinkWithi18n } from '@/infra/link/withi18n';
import styles from '@/interface/ui/_shared/items/index.module.scss';
import CalenderButtonGroup from '@/interface/ui/_shared/items/calenderButtonGroup';
import { routes } from '@/config/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Archive from '@/domain/archives';
import Launch from '@/domain/launch';
import Event from '@/domain/events';
import { formatPriceToYen } from '@/util/currencyHelper';
import colors from '@/constants/colors';
import { hasProperty } from '@/util/commonUtil';

type Props = {
  items: (Launch | Event | Archive)[];
  rowNumber: number;
  country: string;
  language: string;
  userAgent?: string;
  mode?: 'pink' | 'default';
};

const Items: React.FC<Props> = ({
  items,
  rowNumber = 1,
  country,
  language,
  userAgent,
  mode,
}) => {
  const listBuilder = rowNumber === 1 ? singleRowItems : multiRowsItems;
  const itemsList = listBuilder(
    items,
    country,
    language,
    userAgent as string,
    mode
  );

  return itemsList;
};

const renderItem = (
  item: Launch | Event,
  country: string,
  language: string,
  userAgent: string,
  mode?: 'pink' | 'default'
) => {
  const isPink = mode === 'pink';

  if (item instanceof Event || hasProperty(item, 'isEvent')) {
    return (
      <li className={styles.item} key={item.id}>
        <div>
          <LinkWithi18n
            to={routes.events.detail(item.id.toString())}
            country={country}
            language={language}
            mode={mode}
          >
            <div className={styles.itemBody}>
              <div className={styles.titleLabel}>
                <div className={styles.title}>{item.title}</div>
              </div>
              <div className={styles.imgContainer}>
                <img
                  className={styles.image}
                  loading="lazy"
                  src={item.coverImage}
                  alt={item.title}
                />
              </div>
            </div>
          </LinkWithi18n>

          <div className={styles.itemFooter}>
            <div />
            {!!item.canCalendar && (
              <CalenderButtonGroup
                item={item}
                userAgent={userAgent}
                position={'left'}
              />
            )}
          </div>
        </div>
      </li>
    );
  }

  return (
    <li className={styles.item} key={item.id}>
      <div>
        <LinkWithi18n
          to={routes.detail(item.id.toString())}
          country={country}
          language={language}
          mode={mode}
        >
          <div className={styles.itemBody}>
            {/* <div className={styles.brand}>{item.brandName}</div> */}
            <div className={styles.titleLabel}>
              <div className={styles.title}>{item.title}</div>
            </div>
            <div className={styles.price}>{formatPriceToYen(item.price)}</div>
            <div className={styles.imgContainer}>
              <img
                className={styles.image}
                loading="lazy"
                src={item.coverImage}
                alt={item.title}
              />
            </div>
          </div>
        </LinkWithi18n>
        <div className={styles.itemFooter}>
          {isPink ? (
            <div />
          ) : (
            <div className={styles.likeCount}>
              <FontAwesomeIcon
                icon="star"
                color={colors.lightGray}
                size="lg"
                className={styles.starIcon}
              />
              <span>{item.likeCount}</span>
            </div>
          )}
          {!item.releaseAtDisplay && item.isLaunch && (
            <CalenderButtonGroup
              item={item}
              userAgent={userAgent}
              position={'left'}
            />
          )}
        </div>
      </div>
    </li>
  );
};

function singleRowItems(
  items: (Launch | Event)[],
  country: string,
  language: string,
  userAgent: string,
  mode?: 'pink' | 'default'
) {
  return (
    <ul className={styles.singleRowItems}>
      {items &&
        items.map((item) => {
          return renderItem(item, country, language, userAgent, mode);
        })}
    </ul>
  );
}

const rendermMultiRowsItem = (
  item: Launch | Event,
  country: string,
  language: string,
  userAgent: string,
  mode?: 'pink' | 'default'
) => {
  const isPink = mode === 'pink';

  if (item instanceof Event || hasProperty(item, 'isEvent')) {
    return (
      <li className={styles.multiRowsItem} key={item.id}>
        <div>
          <LinkWithi18n
            to={routes.events.detail(item.id.toString())}
            country={country}
            language={language}
            mode={mode}
          >
            <div className={styles.multiRowsItemBody}>
              <div className={styles.titleLabel}>
                <div className={styles.title}>{item.title}</div>
              </div>
              {multiRowsImage(item)}
            </div>
          </LinkWithi18n>
          <div className={styles.multiRowsItemFooter}>
            <div />
            {!!item.canCalendar && (
              <CalenderButtonGroup
                item={item}
                userAgent={userAgent}
                position={'left'}
              />
            )}
          </div>
        </div>
      </li>
    );
  }

  // ListsItemの場合
  return (
    <li className={styles.multiRowsItem} key={item.id}>
      <div>
        <LinkWithi18n
          to={routes.detail(item.id.toString())}
          country={country}
          language={language}
          mode={mode}
        >
          <div className={styles.multiRowsItemBody}>
            {item.noImage ? multiRowsNoImage(item) : multiRowsImage(item)}
            <div className={styles.titleLabel}>
              <div className={styles.title}>{item.title}</div>
            </div>
            <div className={styles.price}>{formatPriceToYen(item.price)}</div>
          </div>
        </LinkWithi18n>
        <div className={styles.multiRowsItemFooter}>
          {isPink ? (
            <div />
          ) : (
            <div className={styles.likeCount}>
              <FontAwesomeIcon
                icon="star"
                color={colors.lightGray}
                size="lg"
                className={styles.starIcon}
              />
              <span>{item.likeCount}</span>
            </div>
          )}
          {!item.releaseAtDisplay && item.isLaunch && (
            <CalenderButtonGroup
              item={item}
              userAgent={userAgent}
              position={'left'}
            />
          )}
        </div>
      </div>
    </li>
  );
};

function multiRowsItems(
  items: (Launch | Event)[],
  country: string,
  language: string,
  userAgent: string,
  mode?: 'pink' | 'default'
) {
  return (
    <ul className={styles.multiRowsItems}>
      {items &&
        items.map((item) => {
          return rendermMultiRowsItem(item, country, language, userAgent, mode);
        })}
    </ul>
  );
}

function multiRowsImage(item: Launch | Event) {
  return (
    <img
      className={styles.image}
      loading="lazy"
      src={item.coverImage}
      alt={item.title}
    />
  );
}

function multiRowsNoImage(item: Launch | Event) {
  return (
    <div className={styles.multiRowsNoImageItemContainer}>
      <img
        className={styles.multiRowsNoImageItemImage}
        loading="lazy"
        src={item.coverImage}
        alt={item.title}
      />
    </div>
  );
}

export default Items;

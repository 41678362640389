const formatPriceFactory = (
  locales: string,
  option: Intl.NumberFormatOptions,
  price: number
): string => {
  const numberFormat: Intl.NumberFormat = new Intl.NumberFormat(
    locales,
    option
  );
  const formattedCurrency = numberFormat.format(price);
  return formattedCurrency;
};

export const formatPriceToYen = (price: number): string => {
  if (!price || price === 0) return '¥ --';
  const formattedCurrency = formatPriceFactory(
    'ja-JP',
    {
      style: 'currency',
      currency: 'JPY',
    },
    price
  );
  return formattedCurrency;
};

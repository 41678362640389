import Shortcut from '@/domain/shortcuts';
import { useErrorHandler } from '@/hooks/useErrorHandler';
import { RootState } from '@/store';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { executeUsercase } from '@/app/executeUsercase';
import itemsGetByShortcuts, {
  ResolvedData,
} from '@/app/items/itemsGetByShortcuts';
import pinkItemsGetByShortcuts from '@/app/pink/items/itemsGetByShortcuts';

const buildShortcutsHooks = (usecase: any) => (type: 'launch' | 'archive') => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { throwError } = useErrorHandler();
  // const [shortcuts, setShortcuts] = useState<Shortcut[]>([]);
  const [itemCount, setItemCount] = useState<number | null>(null);
  const [eventIds, setEventIds] = useState<number[]>([]);
  const [activeShortcut, setActiveShortcut] = useState<Shortcut | null>(null);

  const shortcuts = useSelector<RootState, Shortcut[] | undefined>((state) =>
    type === 'launch'
      ? state.domain.launch.shortcuts
      : state.domain.archives.shortcuts
  );

  // const fetchAsync = useCallback(
  //   async (_page?: number) => {
  //     setIsLoading(true);
  //     setIsError(false);

  //     try {
  //       const { data } = await Api.fetchShortcuts(type);

  //       if (data?.shortcuts) {
  //         setShortcuts(data.shortcuts.map((item: any) => new Shortcut(item)));
  //       }
  //     } catch (error) {
  //       throwError(error, 'Shortcutsの取得に失敗しました');
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   },
  //   [throwError, type]
  // );

  const fetchItemsAsync = useCallback(
    async (shortcut: Shortcut, _page?: number) => {
      setIsLoading(true);
      setIsError(false);

      try {
        const data = await executeUsercase<
          ResolvedData,
          { type: 'launch' | 'archive'; shortcutId: number }
        >(usecase, {
          type,
          shortcutId: shortcut.id,
        });

        if (data) {
          setActiveShortcut(shortcut);
          setItemCount(data.totalCount);
          setEventIds(data.eventIds);
          return data;
        }
      } catch (error) {
        throwError(error, 'LISTSの取得に失敗しました');
      } finally {
        setIsLoading(false);
      }
    },
    [throwError, type]
  );

  const clearActivceShortcut = useCallback(() => {
    setActiveShortcut(null);
    setItemCount(null);
    setEventIds([]);
  }, []);

  return {
    shortcuts,
    activeShortcut,
    isLoading,
    isError,
    // fetchAsync,
    fetchItemsAsync,
    clearActivceShortcut,
    itemCount,
    eventIds,
  };
};

export const useShortcuts = buildShortcutsHooks(itemsGetByShortcuts);
export const usePinkShortcuts = buildShortcutsHooks(pinkItemsGetByShortcuts);

import React from 'react';
import Shortcut from '@/domain/shortcuts';
import styles from '@/interface/ui/_shared/common/ShortcutsBar.module.scss';
import { ShortcutTip } from '@/interface/ui/_shared/common/ShortcutTip';

interface Props {
  selected?: number;
  shortcuts: Shortcut[];
  hasEvent?: boolean;
  hasLottery?: boolean;
  eventSelected?: boolean;
  lotterySelected?: boolean;
  onPress: (shortcut: Shortcut) => void;
  onPressAll: () => void;
  onPressEvent?: () => void;
  onPressLottery?: () => void;
}

export const ShortcutsBar = ({
  shortcuts,
  selected,
  hasEvent,
  hasLottery,
  eventSelected,
  lotterySelected,
  onPress,
  onPressAll,
  onPressEvent,
  onPressLottery,
}: Props) => {
  return (
    <div className={styles.contaner}>
      <ul className={styles.tips}>
        <ShortcutTip
          onPress={onPressAll}
          name={'すべて'}
          key={`shortcut-all`}
          active={!selected && !eventSelected && !lotterySelected}
        />
        {hasEvent && (
          <ShortcutTip
            onPress={onPressEvent}
            name={'イベント'}
            key={`shortcut-event`}
            active={!selected && eventSelected}
          />
        )}
        {hasLottery && (
          <ShortcutTip
            onPress={onPressLottery}
            name={'抽選'}
            key={'shortcut-lottery'}
            active={!selected && lotterySelected}
          />
        )}
        {shortcuts.map((s, i) => {
          const _onPress = () => onPress(s);
          return (
            <ShortcutTip
              onPress={_onPress}
              name={s.name}
              key={`shortcut-${s.id}`}
              active={selected === s.id}
            />
          );
        })}
      </ul>
    </div>
  );
};
